import { red } from '@mui/material/colors'
import React,{useContext, useEffect} from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import MyContext from '../../store/MyContext';
import axiosInstance from '../../config/axios/AxiosConfiguration';
import { FiChevronsRight } from "react-icons/fi";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiFillMinusCircle } from "react-icons/ai";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { BsCalendar2Date } from "react-icons/bs";
import { TbCoinRupeeFilled, TbDashboardOff } from "react-icons/tb";
import { AiOutlineMail } from "react-icons/ai";
import { PiPhoneCallLight } from "react-icons/pi";
import { FaRegUser } from "react-icons/fa";
import '../../style/SuperAdminDashboard/BalanceSheet.css'
import { toast } from 'react-toastify';
import { BsCurrencyRupee } from "react-icons/bs";
import {useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {Pagination} from 'react-bootstrap';
import { numberToWords } from "amount-to-words";
import InvoiceGenerationForUser from '../SuperAdminComponent/InvoiceGenerationForUser';



function BalanceSheet() {

  const token = localStorage.getItem('admintoken');

  const [credit,setCredit] = useState([])
  const [show2, setShow2] = useState(false);
  const [show1, setShow1] = useState(false);
  const{userid,setUserid}=useContext(MyContext)
  const[getData,setGetData]=useState([])
  const[totalCredit,setTotalCredit]=useState([])
  const [loading, setLoading] = useState(false);
  const[transactionData,setTransactionData] = useState([])
  const [numberInWords, setNumberInWords] = useState('');
  const [triger, setTriger] = useState(Date.now());

  const[transOtherData,setTransOtherData] = useState([{
  }])

  const [isDebit,setIsDebit] = useState()

  



  const [inputCreditData,setInputCreditData] = useState({
    amount_paid:'',
    payment_date:'',
    payment_time:'',
    payment_method:'',
    payment_mode:'offline',
    product_service:'advance',
    transaction_id:''
  })


  const [inputDebitData,setInputDebitData] = useState({
    debit_amount:'',
    purpose_of_debit:''
  
  })

  const [show, setShow] = useState(false);
  const target = useRef(null);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);


  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);


// date and time for not showing future date and time
  const today = new Date().toISOString().split('T')[0];
  const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false });


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // Adjust the number of items per page as needed

  // Assuming you have a data array named getData
  const totalItems = getData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const popover = (
    <Popover id="popover-basic" style={{textAlign:'center'}}>
     
      <Card style={{ textAlign:'start', width: '18rem', background: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(10px)', border: '1px solid rgba(255, 255, 255, 0.2)' }}>
      <Card.Body style={{}}> 
        <Card.Title>ALL DETAILS</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{transactionData?.debited_by?.name ? "Debited by" +" "+  transactionData?.debited_by?.name :"" }</Card.Subtitle>
        <Card.Text>
          Date: {transOtherData.date ? transOtherData.date +""+" Time :"+transOtherData.time:transactionData?.debited_on}
        </Card.Text>
        <Card.Text> 
          Amount : {transOtherData.amount ? transOtherData.amount: transactionData?.debit_amount}
        </Card.Text>
        <Card.Text>
          {transactionData?.purpose_of_debit? " Description :"+transactionData?.purpose_of_debit:""}
        </Card.Text>
        <Card.Text>
           {isDebit ?"" :"payment mode : offline"}
        </Card.Text>
        <Card.Text>
         payment type: {transOtherData.type ? transOtherData.type:transactionData?.debit_transaction_details?.product_service}
        </Card.Text>
        <Card.Text>
         {transOtherData.transId === "0" ? "":" Transaction Id :"+transOtherData.transId}
        </Card.Text>
       
       
      </Card.Body>
    </Card>
     
    </Popover>
  );
  

  const fetchedData = async () => {
 
   
  const response = await axiosInstance.get(`userapp/single/user/details/for/balancesheet/${userid}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  setLoading(true)
  setGetData(response.data)
 


}
// console.log(getData);
const fetchTransactionData = async (id,idDebit) => {
if(idDebit === "Debit"){
  setIsDebit(true)
  
  try {
    const response = await axiosInstance.get(
      `userapp/user/debit/details/in/balancesheet/${id}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    
    // Set transactionData state after getting the response
    setTransactionData(response.data);
    // setLoading(true); // Set loading state after setting transactionData

  } catch (error) {
    // Handle errors here
    console.error('Error:', error);
   
  }
}else{
  setIsDebit(false)
  setTransactionData('')
}
  console.log(id);
};



const handleCredit = async () => {
  // Check if any required fields are empty
  if (
    inputCreditData.amount_paid === "" ||
    inputCreditData.payment_date === "" ||
    inputCreditData.payment_time === "" ||
    inputCreditData.payment_method === "" ||
    inputCreditData.payment_mode === "" ||
    inputCreditData.product_service === "" ||
    inputCreditData.transaction_id === ""
  ) {
    toast.warning("Please fill all the fields");
  } else if (inputCreditData.amount_paid < 0){
    toast.warning("Invalid credit amount")
  } 
  
  else {
    try {
      // Make the API call to add user advance payment
      const response = await axiosInstance.post(
        `userapp/admin/add/user/advance/payment/${userid}`,
        inputCreditData,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        
      );
      handleClose2();
      // Handle the response here
      console.log(response.data);
      toast.success(`Credit balance added successfully`);

      // Optionally, call other functions here if needed
      fetchedData();
      totalcredit();
    } catch (error) {
      // Handle errors here
      console.error('Error:', error);
    }
  
  }

};

// Call the handleCredit function when needed
// For example: handleCredit();

const handleDebit = async () =>{
  if( inputDebitData.debit_amount === "" || inputDebitData.purpose_of_debit === "" ){
    // setShow1(false)
    toast.warning('please fill all the fields')
   

  }else if (inputDebitData.debit_amount > totalCredit?.user_currect_balance || inputDebitData.debit_amount === "0"){
   
    toast.warning(`Insufficient credit balance`,{
      position:'top-right'
    })
    
  } else if(inputDebitData.debit_amount < 0){
    toast.warning(`please enter a valid amount`,{
      position:'top-right'
    })

  }
  
  else{
    try {
      const response = await axiosInstance.post(
        `userapp/admin/add/user/debit/details/in/balancesheet/${userid}`,
        inputDebitData,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      handleClose1();
      toast.success('Debited successfully')
  
      // Handle the response here
      console.log(response.data);
      setTotalCredit(response.data)
      
    } catch (error) {
      // Handle errors here
      console.error('Error:', error);
    }
  }
  fetchedData()
totalcredit()
}

const totalcredit = async () => {
  try {
    const response = await axiosInstance.get(
      `userapp/user/balancesheet/amount/details/${userid}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
   

    // Handle the response here
    // console.log(response.data);
    setTotalCredit(response.data)
    
  } catch (error) {
    // Handle errors here
    console.error('Error:', error);
  }
}
// console.log('abc',totalCredit);
useEffect(() => {
  const convertNumberToWords = () => {
    if (!isNaN(inputCreditData.amount_paid) && inputCreditData.amount_paid !== '') {
      const numToWord = numberToWords(inputCreditData.amount_paid);
      setNumberInWords(numToWord);
    } else {
      setNumberInWords('');
    }
  };

  convertNumberToWords();


fetchedData()
totalcredit()
},[inputCreditData.amount_paid,triger])


// console.log(transactionData);
// console.log(getData);
// console.log(transOtherData);

// console.log(today);
// console.log(currentTime);

console.log(numberInWords);

  return (
    <div>
      {
        loading ?(<div>
          <Row>
      <Navbar bg="light">
        <Container>
          <div>
            <h5 style={{ fontWeight: 'bold' }}> <FaRegUser/> {getData[0]?.user_link?.user?.name}</h5>
            <p> <PiPhoneCallLight/> {getData[0]?.user_link?.user?.phone}<br />
              <p> <AiOutlineMail/> {getData[0]?.user_link?.user?.email}</p>
            </p>
          </div>
          <Table striped bordered hover size="sm" className='' style={{ width: '8rem' }}>
            <thead>
              <tr>
                <th style={{color:'green'}}> CREDIT </th>
                <th style={{color:'red'}}> DEBIT </th>
                <th > BALANCE </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{color:'green'}}>{totalCredit?.total_credit}</td>
                <td style={{color:'red'}}>{totalCredit?.total_debit}</td>
                <td>{totalCredit?.user_currect_balance}</td>
              </tr>
            </tbody>
          </Table>
          <div className="text-center "> 
          {/* <Button variant="light" className="mx-2 btn-sm  px-3" onClick={() => {handleShow(); setCredit(true);}} style={{color:'green' ,fontWeight:'bold'}} >CREDIT <AiOutlinePlusCircle/>   </Button>
          
          <Button variant="light" className="mx-2 btn-sm px-3"  onClick={() => {handleShow(); setCredit(false);}} style={{color:'red' ,fontWeight:'bold'}}>DEBIT  <AiOutlineMinusCircle/> </Button> */}
         

         <button 
         onClick={() => {handleShow1(); setCredit(true);}}  style={{fontWeight:'bold' ,marginRight:'1rem'}}
         className='btnn2' > Debit <AiOutlineMinusCircle/>
          </button>
           

          <button
           className='btnn' onClick={() => {handleShow2(); setCredit(true);}} style={{fontWeight:'bold'}} > Credit <AiOutlinePlusCircle/> 
          </button>
          </div>
        </Container>
      </Navbar>
    </Row>

        <Row>
            <Col style={{textAlign:'center'}} >
              <h6 className='m-3' style={{textDecoration:'underline'}}>TRANSACTIONS</h6>
            </Col>

            <Table striped bordered hover>
      <thead>
        <tr style={{textAlign:'center', position:'sticky', top:'1px'}}>
          <th>#</th>
          <th>Trans type</th>
          <th>Amount    </th>
          <th>Date & time  <BsCalendar2Date/></th>
          <th>Action</th>
          {/* <th style={{textAlign:'center'}}>Transaction ID</th> */}
        </tr>
      </thead>
      <tbody>

 
          
         
         
      

   
     {currentItems.map((item,index) => (
    
      <tr >

        
       <td>{index+1}
       <br />
       {/* {item?.debit_transaction_details?.payment_mode} */}
       </td>
       
    
       <td style={{color:item.product_service === "advance" ? "green" :"red"}}>{item.product_service}</td>
       <td > <BsCurrencyRupee/>{item.payment_total_amount_paid} </td>
       <td >{item.payment_done_at.slice(0,10)} [ {item.payment_done_at.toString().slice(11,16)} ]</td>
      
       <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
       <td
       style={{display:'flex', gap:'1rem'}}>
        <button className='btn btn-sm btn-light' onClick={() => { fetchTransactionData(item.id,item.product_service); setTransOtherData({...transOtherData,transId:item.payment_transaction_id,amount:item.payment_total_amount_paid,
      time:item.payment_done_at.toString().slice(11,16),date:item.payment_done_at.slice(0,10),type:item.product_service,paymentMode:item.payment_mode,
    }); }}> View More</button> 
     <InvoiceGenerationForUser
          transaction_id={item.id}
          done_invoice_generation_by_admin_to_user={
            item.done_invoice_generation_by_admin_to_user
          }
          done_invoice_generation_by_user={item.done_invoice_generation_by_user}
          userCompletedProfile={
            item.user_link?.user_details?.user_profile_completed
          }
          setTriger={setTriger}
        />
   <br />
  
</td>

       {/* <td>{item.id}</td> */}
  </OverlayTrigger>
       {/* <td>{item.payment_transaction_id}</td> */}
       </tr>
     ))}
 

 

</tbody>

       
     
    </Table>

    



        </Row>
        <Pagination className="mt-3 text-center">
        {Array.from({ length: totalPages }).map((_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>


        <Modal size='lg'
        animation = 'fode'
        style={{backgroundColor:'transparent'}}
        show={show2} onHide={handleClose2}>
        <Modal.Header closeButton style={{textAlign:'center'}}>
          <Modal.Title><p className="title" style={{color:'green'}}>Credit Amount</p> </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{display:'flex', justifyContent:'center'}}>

        <form className="form" >
     
      <div >


  
    <label>
      <input
        required
        placeholder=""
        type="number"
        className="input"
        name="amount"
        onChange={(e) => setInputCreditData((inputCreditData) => ({ ...inputCreditData,amount_paid: e.target.value }))}
      />
      <span  style={{fontSize:'12px'}}>Amount</span>
    </label>
    <p style={{color:'red', fontStyle:'italic'}}>{numberInWords}</p>
   


      </div>

    

 
 
       <div className="" style={{display:'flex', justifyContent:'space-between'}}>

       


<div style={{width:'49%'}}>
  <p  style={{fontSize:'1rem', marginBottom:'-1px'}}>Payment date</p>
<label>
   <input
     required
     placeholder="Payment Date"
     type="date"
     className="input"
     name="date"
     max={today}
     onChange={(e) => setInputCreditData(inputCreditData => ({ ...inputCreditData,payment_date: e.target.value }))}
   />
  
 </label>
</div>

<div style={{width:'49%'}}>
  <p style={{fontSize:'1rem', marginBottom:'-1px'}}>payment time</p>
<label>
   <input
     required
     placeholder=""
     type="time"
     className="input"
     name="time"
     max={currentTime}
     onChange={(e) => setInputCreditData(inputCreditData => ({ ...inputCreditData,payment_time: e.target.value }))}
   
   />
   <span></span>
 </label>
</div>


</div>


      <div className="flex">


<label>
<span  style={{fontSize:'12px'}}> product service</span>
   <input
     required
     placeholder=""
     type="text"
     className="input"
     name="product_service"
     value={"advance"}
     readOnly
     onChange={(e) => setInputCreditData(inputCreditData => ({ ...inputCreditData,product_service: e.target.value }))}
   />
  
 </label>
 <label>
 <span style={{fontSize:'12px'}}>payment mode</span>
   <input
     required
     placeholder="payment_mode"
     type="text"
     className="input"
     name="payment_mode"
     value={"offline"}
     onChange={(e) => setInputCreditData(inputCreditData => ({ ...inputCreditData,payment_mode: e.target.value }))}
   
   />
  
 </label>
</div>


<div style={{width:'100%', display:'flex', gap:'0.5rem'}}>


<div style={{width:'49%'}}>
<p style={{fontSize:'12px', marginBottom:'-1px'}} >Trans Id</p>
<label>
         <input
           required
           type=""
           className="input"
           name="transactionId"
           onChange={(e) => setInputCreditData(inputCreditData=> ({ ...inputCreditData,transaction_id: e.target.value }))}
       
         />
         
       </label>
</div>

  <div style={{width:'49%'}}>
  <p  style={{fontSize:'12px', marginBottom:'-1px'}}>Payment Method</p>
       <label>
      
  <select
    required
    className="input"
    name="payment_method"
    onChange={(e) => setInputCreditData((inputCreditData) => ({ ...inputCreditData, payment_method: e.target.value }))}
  >
    
    <option value="">SELECT</option>
    <option value="google_pay">GOOGLE PAY</option>
    <option value="neft">NEFT</option>
    <option value="bank_transfer">BANK TRANSFER</option>
    <option value="paytm">PAYTM</option>
    <option value="phonepe">PHONEPE</option>
    {/* Add more options as needed */}
    
  </select>
  <span></span>
 
 
</label>
  </div>



</div>


   
    </form>
 

        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="success" onClick={() => { handleCredit(); }}>
  Credit
</Button>

        </Modal.Footer>
      </Modal>


      <Modal size='sm'
        animation = 'fode'
        style={{backgroundColor:'transparent'}}
        show={show1} onHide={handleClose1}>
        <Modal.Header closeButton style={{textAlign:'center'}}>
          <Modal.Title><p className="title" style={{color:'red'}}>DEBIT AMOUNT</p> </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{display:'flex', justifyContent:'center'}}>

        <form className="form" >
     
      <div>

        <p style={{fontSize:'12px', color:'red', fontFamily:'monospace', fontWeight:'bold'}}>Available credit balance: {totalCredit?.user_currect_balance}</p>


      <label>
      <input
        required
        placeholder=""
        type="text"
        className="input"
        name="description"
        onChange={(e) => setInputDebitData((inputDebitData) => ({ ...inputDebitData,purpose_of_debit: e.target.value }))}
      />
      <span>Description</span>
    </label>
  
    <label>
      <input
        required
        placeholder=""
        type="number"
        className="input"
        name="amount"
        onChange={(e) => setInputDebitData((inputDebitData) => ({ ...inputDebitData,debit_amount: e.target.value }))}
      />
      <span>Amount</span>
    </label>


      </div>


    </form>
 

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="danger" onClick={() => { handleDebit(); }}>
  Debit
</Button>

        </Modal.Footer>
      </Modal>
        </div>):(<div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
    <div>
    <div className="loader">
      <div className="loader__circle"></div>
      <div className="loader__circle"></div>
      <div className="loader__circle"></div>
      <div className="loader__circle"></div>
    </div>
    </div>
        </div>)
      }



      
    </div>
  )
}

export default BalanceSheet
















// import { red } from '@mui/material/colors'
// import React,{useContext, useEffect} from 'react'
// import { Button, Col, Row } from 'react-bootstrap'
// import Card from 'react-bootstrap/Card';
// import Table from 'react-bootstrap/Table';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import Container from 'react-bootstrap/Container';
// import { useState } from 'react';
// import Form from 'react-bootstrap/Form';
// import Modal from 'react-bootstrap/Modal';
// import MyContext from '../../store/MyContext';
// import axiosInstance from '../../config/axios/AxiosConfiguration';
// import { FiChevronsRight } from "react-icons/fi";
// import { AiOutlinePlusCircle } from "react-icons/ai";
// import { AiFillMinusCircle } from "react-icons/ai";
// import { AiOutlineMinusCircle } from "react-icons/ai";
// import { BsCalendar2Date } from "react-icons/bs";
// import { TbCoinRupeeFilled, TbDashboardOff } from "react-icons/tb";
// import { AiOutlineMail } from "react-icons/ai";
// import { PiPhoneCallLight } from "react-icons/pi";
// import { FaRegUser } from "react-icons/fa";
// import '../../style/SuperAdminDashboard/BalanceSheet.css'
// import { toast } from 'react-toastify';
// import { BsCurrencyRupee } from "react-icons/bs";
// import {useRef } from 'react';
// import Overlay from 'react-bootstrap/Overlay';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Popover from 'react-bootstrap/Popover';
// import {Pagination} from 'react-bootstrap';
// import { numberToWords } from "amount-to-words";


// function BalanceSheet() {

//   const token = localStorage.getItem('admintoken');

//   const [credit,setCredit] = useState([])
//   const [show2, setShow2] = useState(false);
//   const [show1, setShow1] = useState(false);
//   const{userid,setUserid}=useContext(MyContext)
//   const[getData,setGetData]=useState([])
//   const[totalCredit,setTotalCredit]=useState([])
//   const [loading, setLoading] = useState(false);
//   const[transactionData,setTransactionData] = useState([])
//   const [numberInWords, setNumberInWords] = useState('');

//   const[transOtherData,setTransOtherData] = useState([{
//   }])

//   const [isDebit,setIsDebit] = useState()

  



//   const [inputCreditData,setInputCreditData] = useState({
//     amount_paid:'',
//     payment_date:'',
//     payment_time:'',
//     payment_method:'',
//     payment_mode:'offline',
//     product_service:'advance',
//     transaction_id:''
//   })


//   const [inputDebitData,setInputDebitData] = useState({
//     debit_amount:'',
//     purpose_of_debit:''
  
//   })

//   const [show, setShow] = useState(false);
//   const target = useRef(null);

//   const handleClose2 = () => setShow2(false);
//   const handleShow2 = () => setShow2(true);


//   const handleClose1 = () => setShow1(false);
//   const handleShow1 = () => setShow1(true);


// // date and time for not showing future date and time
//   const today = new Date().toISOString().split('T')[0];
//   const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false });


//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 6; // Adjust the number of items per page as needed

//   // Assuming you have a data array named getData
//   const totalItems = getData.length;
//   const totalPages = Math.ceil(totalItems / itemsPerPage);
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = getData.slice(indexOfFirstItem, indexOfLastItem);

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const popover = (
//     <Popover id="popover-basic" style={{textAlign:'center'}}>
     
//       <Card style={{ textAlign:'start', width: '18rem', background: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(10px)', border: '1px solid rgba(255, 255, 255, 0.2)' }}>
//       <Card.Body style={{}}> 
//         <Card.Title>ALL DETAILS</Card.Title>
//         <Card.Subtitle className="mb-2 text-muted">{transactionData?.debited_by?.name ? "Debited by" +" "+  transactionData?.debited_by?.name :"" }</Card.Subtitle>
//         <Card.Text>
//           Date: {transOtherData.date ? transOtherData.date +""+" Time :"+transOtherData.time:transactionData?.debited_on}
//         </Card.Text>
//         <Card.Text> 
//           Amount : {transOtherData.amount ? transOtherData.amount: transactionData?.debit_amount}
//         </Card.Text>
//         <Card.Text>
//           {transactionData?.purpose_of_debit? " Description :"+transactionData?.purpose_of_debit:""}
//         </Card.Text>
//         <Card.Text>
//            {isDebit ?"" :"payment mode : offline"}
//         </Card.Text>
//         <Card.Text>
//          payment type: {transOtherData.type ? transOtherData.type:transactionData?.debit_transaction_details?.product_service}
//         </Card.Text>
//         <Card.Text>
//          {transOtherData.transId === "0" ? "":" Transaction Id :"+transOtherData.transId}
//         </Card.Text>
       
       
//       </Card.Body>
//     </Card>
     
//     </Popover>
//   );
  

//   const fetchedData = async () => {
 
   
//   const response = await axiosInstance.get(`userapp/single/user/details/for/balancesheet/${userid}`, {
//     headers: {
//       'Authorization': `Bearer ${token}`
//     }
//   })
//   setLoading(true)
//   setGetData(response.data)
 


// }
// // console.log(getData);
// const fetchTransactionData = async (id,idDebit) => {
// if(idDebit === "Debit"){
//   setIsDebit(true)
  
//   try {
//     const response = await axiosInstance.get(
//       `userapp/user/debit/details/in/balancesheet/${id}`,
//       {
//         headers: {
//           'Authorization': `Bearer ${token}`
//         }
//       }
//     );
    
//     // Set transactionData state after getting the response
//     setTransactionData(response.data);
//     // setLoading(true); // Set loading state after setting transactionData

//   } catch (error) {
//     // Handle errors here
//     console.error('Error:', error);
   
//   }
// }else{
//   setIsDebit(false)
//   setTransactionData('')
// }
//   console.log(id);
// };



// const handleCredit = async () => {
//   // Check if any required fields are empty
//   if (
//     inputCreditData.amount_paid === "" ||
//     inputCreditData.payment_date === "" ||
//     inputCreditData.payment_time === "" ||
//     inputCreditData.payment_method === "" ||
//     inputCreditData.payment_mode === "" ||
//     inputCreditData.product_service === "" ||
//     inputCreditData.transaction_id === ""
//   ) {
//     toast.warning("Please fill all the fields");
//   } else if (inputCreditData.amount_paid < 0){
//     toast.warning("Invalid credit amount")
//   } 
  
//   else {
//     try {
//       // Make the API call to add user advance payment
//       const response = await axiosInstance.post(
//         `userapp/admin/add/user/advance/payment/${userid}`,
//         inputCreditData,
//         {
//           headers: {
//             'Authorization': `Bearer ${token}`
//           }
//         }
        
//       );
//       handleClose2();
//       // Handle the response here
//       console.log(response.data);
//       toast.success(`Credit balance added successfully`);

//       // Optionally, call other functions here if needed
//       fetchedData();
//       totalcredit();
//     } catch (error) {
//       // Handle errors here
//       console.error('Error:', error);
//     }
  
//   }

// };

// // Call the handleCredit function when needed
// // For example: handleCredit();

// const handleDebit = async () =>{
//   if( inputDebitData.debit_amount === "" || inputDebitData.purpose_of_debit === "" ){
//     // setShow1(false)
//     toast.warning('please fill all the fields')
   

//   }else if (inputDebitData.debit_amount > totalCredit?.user_currect_balance || inputDebitData.debit_amount === "0"){
   
//     toast.warning(`Insufficient credit balance`,{
//       position:'top-right'
//     })
    
//   } else if(inputDebitData.debit_amount < 0){
//     toast.warning(`please enter a valid amount`,{
//       position:'top-right'
//     })

//   }
  
//   else{
//     try {
//       const response = await axiosInstance.post(
//         `userapp/admin/add/user/debit/details/in/balancesheet/${userid}`,
//         inputDebitData,
//         {
//           headers: {
//             'Authorization': `Bearer ${token}`
//           }
//         }
//       );
//       handleClose1();
//       toast.success('Debited successfully')
  
//       // Handle the response here
//       console.log(response.data);
//       setTotalCredit(response.data)
      
//     } catch (error) {
//       // Handle errors here
//       console.error('Error:', error);
//     }
//   }
//   fetchedData()
// totalcredit()
// }

// const totalcredit = async () => {
//   try {
//     const response = await axiosInstance.get(
//       `userapp/user/balancesheet/amount/details/${userid}`,
//       {
//         headers: {
//           'Authorization': `Bearer ${token}`
//         }
//       }
//     );
   

//     // Handle the response here
//     // console.log(response.data);
//     setTotalCredit(response.data)
    
//   } catch (error) {
//     // Handle errors here
//     console.error('Error:', error);
//   }
// }
// // console.log('abc',totalCredit);
// useEffect(() => {
//   const convertNumberToWords = () => {
//     if (!isNaN(inputCreditData.amount_paid) && inputCreditData.amount_paid !== '') {
//       const numToWord = numberToWords(inputCreditData.amount_paid);
//       setNumberInWords(numToWord);
//     } else {
//       setNumberInWords('');
//     }
//   };

//   convertNumberToWords();


// fetchedData()
// totalcredit()
// },[inputCreditData.amount_paid])


// // console.log(transactionData);
// // console.log(getData);
// // console.log(transOtherData);

// // console.log(today);
// // console.log(currentTime);

// console.log(numberInWords);

//   return (
//     <div>
//       {
//         loading ?(<div>
//           <Row>
//       <Navbar bg="light">
//         <Container>
//           <div>
//             <h5 style={{ fontWeight: 'bold' }}> <FaRegUser/> {getData[0]?.user_link?.user?.name}</h5>
//             <p> <PiPhoneCallLight/> {getData[0]?.user_link?.user?.phone}<br />
//               <p> <AiOutlineMail/> {getData[0]?.user_link?.user?.email}</p>
//             </p>
//           </div>
//           <Table striped bordered hover size="sm" className='' style={{ width: '8rem' }}>
//             <thead>
//               <tr>
//                 <th style={{color:'green'}}> CREDIT </th>
//                 <th style={{color:'red'}}> DEBIT </th>
//                 <th > BALANCE </th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td style={{color:'green'}}>{totalCredit?.total_credit}</td>
//                 <td style={{color:'red'}}>{totalCredit?.total_debit}</td>
//                 <td>{totalCredit?.user_currect_balance}</td>
//               </tr>
//             </tbody>
//           </Table>
//           <div className="text-center "> 
//           {/* <Button variant="light" className="mx-2 btn-sm  px-3" onClick={() => {handleShow(); setCredit(true);}} style={{color:'green' ,fontWeight:'bold'}} >CREDIT <AiOutlinePlusCircle/>   </Button>
          
//           <Button variant="light" className="mx-2 btn-sm px-3"  onClick={() => {handleShow(); setCredit(false);}} style={{color:'red' ,fontWeight:'bold'}}>DEBIT  <AiOutlineMinusCircle/> </Button> */}
         

//          <button 
//          onClick={() => {handleShow1(); setCredit(true);}}  style={{fontWeight:'bold' ,marginRight:'1rem'}}
//          className='btnn2' > Debit <AiOutlineMinusCircle/>
//           </button>
           

//           <button
//            className='btnn' onClick={() => {handleShow2(); setCredit(true);}} style={{fontWeight:'bold'}} > Credit <AiOutlinePlusCircle/> 
//           </button>
//           </div>
//         </Container>
//       </Navbar>
//     </Row>

//         <Row>
//             <Col style={{textAlign:'center'}} >
//               <h6 className='m-3' style={{textDecoration:'underline'}}>TRANSACTIONS</h6>
//             </Col>

//             <Table striped bordered hover>
//       <thead>
//         <tr style={{textAlign:'center', position:'sticky', top:'1px'}}>
//           <th>#</th>
//           <th>Trans type</th>
//           <th>Amount    </th>
//           <th>Date & time  <BsCalendar2Date/></th>
//           <th>Action</th>
//           {/* <th style={{textAlign:'center'}}>Transaction ID</th> */}
//         </tr>
//       </thead>
//       <tbody>

 
          
         
         
      

   
//      {currentItems.map((item,index) => (
    
//       <tr >

        
//        <td>{index+1}
//        <br />
//        {/* {item?.debit_transaction_details?.payment_mode} */}
//        </td>
       
    
//        <td style={{color:item.product_service === "advance" ? "green" :"red"}}>{item.product_service}</td>
//        <td > <BsCurrencyRupee/>{item.payment_total_amount_paid} </td>
//        <td >{item.payment_done_at.slice(0,10)} [ {item.payment_done_at.toString().slice(11,16)} ]</td>
      
//        <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
//        <td><button className='btn btn-sm btn-light' onClick={() => { fetchTransactionData(item.id,item.product_service); setTransOtherData({...transOtherData,transId:item.payment_transaction_id,amount:item.payment_total_amount_paid,
//       time:item.payment_done_at.toString().slice(11,16),date:item.payment_done_at.slice(0,10),type:item.product_service,paymentMode:item.payment_mode,
//     }); }}> View More</button>
// </td>

//        {/* <td>{item.id}</td> */}
//   </OverlayTrigger>
//        {/* <td>{item.payment_transaction_id}</td> */}
//        </tr>
//      ))}
 

 

// </tbody>

       
     
//     </Table>

    



//         </Row>
//         <Pagination className="mt-3 text-center">
//         {Array.from({ length: totalPages }).map((_, index) => (
//           <Pagination.Item
//             key={index + 1}
//             active={index + 1 === currentPage}
//             onClick={() => handlePageChange(index + 1)}
//           >
//             {index + 1}
//           </Pagination.Item>
//         ))}
//       </Pagination>


//         <Modal size='lg'
//         animation = 'fode'
//         style={{backgroundColor:'transparent'}}
//         show={show2} onHide={handleClose2}>
//         <Modal.Header closeButton style={{textAlign:'center'}}>
//           <Modal.Title><p className="title" style={{color:'green'}}>Credit Amount</p> </Modal.Title>
//         </Modal.Header>
//         <Modal.Body style={{display:'flex', justifyContent:'center'}}>

//         <form className="form" >
     
//       <div >


  
//     <label>
//       <input
//         required
//         placeholder=""
//         type="number"
//         className="input"
//         name="amount"
//         onChange={(e) => setInputCreditData((inputCreditData) => ({ ...inputCreditData,amount_paid: e.target.value }))}
//       />
//       <span  style={{fontSize:'12px'}}>Amount</span>
//     </label>
//     <p style={{color:'red', fontStyle:'italic'}}>{numberInWords}</p>
   


//       </div>

    

 
 
//        <div className="" style={{display:'flex', justifyContent:'space-between'}}>

       


// <div style={{width:'49%'}}>
//   <p  style={{fontSize:'1rem', marginBottom:'-1px'}}>Payment date</p>
// <label>
//    <input
//      required
//      placeholder="Payment Date"
//      type="date"
//      className="input"
//      name="date"
//      max={today}
//      onChange={(e) => setInputCreditData(inputCreditData => ({ ...inputCreditData,payment_date: e.target.value }))}
//    />
  
//  </label>
// </div>

// <div style={{width:'49%'}}>
//   <p style={{fontSize:'1rem', marginBottom:'-1px'}}>payment time</p>
// <label>
//    <input
//      required
//      placeholder=""
//      type="time"
//      className="input"
//      name="time"
//      max={currentTime}
//      onChange={(e) => setInputCreditData(inputCreditData => ({ ...inputCreditData,payment_time: e.target.value }))}
   
//    />
//    <span></span>
//  </label>
// </div>


// </div>


//       <div className="flex">


// <label>
// <span  style={{fontSize:'12px'}}> product service</span>
//    <input
//      required
//      placeholder=""
//      type="text"
//      className="input"
//      name="product_service"
//      value={"advance"}
//      readOnly
//      onChange={(e) => setInputCreditData(inputCreditData => ({ ...inputCreditData,product_service: e.target.value }))}
//    />
  
//  </label>
//  <label>
//  <span style={{fontSize:'12px'}}>payment mode</span>
//    <input
//      required
//      placeholder="payment_mode"
//      type="text"
//      className="input"
//      name="payment_mode"
//      value={"offline"}
//      onChange={(e) => setInputCreditData(inputCreditData => ({ ...inputCreditData,payment_mode: e.target.value }))}
   
//    />
  
//  </label>
// </div>


// <div style={{width:'100%', display:'flex', gap:'0.5rem'}}>


// <div style={{width:'49%'}}>
// <p style={{fontSize:'12px', marginBottom:'-1px'}} >Trans Id</p>
// <label>
//          <input
//            required
//            type=""
//            className="input"
//            name="transactionId"
//            onChange={(e) => setInputCreditData(inputCreditData=> ({ ...inputCreditData,transaction_id: e.target.value }))}
       
//          />
         
//        </label>
// </div>

//   <div style={{width:'49%'}}>
//   <p  style={{fontSize:'12px', marginBottom:'-1px'}}>Payment Method</p>
//        <label>
      
//   <select
//     required
//     className="input"
//     name="payment_method"
//     onChange={(e) => setInputCreditData((inputCreditData) => ({ ...inputCreditData, payment_method: e.target.value }))}
//   >
    
//     <option value="">SELECT</option>
//     <option value="google_pay">GOOGLE PAY</option>
//     <option value="neft">NEFT</option>
//     <option value="bank_transfer">BANK TRANSFER</option>
//     <option value="paytm">PAYTM</option>
//     <option value="phonepe">PHONEPE</option>
//     {/* Add more options as needed */}
    
//   </select>
//   <span></span>
 
 
// </label>
//   </div>



// </div>


   
//     </form>
 

//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="danger" onClick={handleClose2}>
//             Close
//           </Button>
//           <Button variant="success" onClick={() => { handleCredit(); }}>
//   Credit
// </Button>

//         </Modal.Footer>
//       </Modal>


//       <Modal size='sm'
//         animation = 'fode'
//         style={{backgroundColor:'transparent'}}
//         show={show1} onHide={handleClose1}>
//         <Modal.Header closeButton style={{textAlign:'center'}}>
//           <Modal.Title><p className="title" style={{color:'red'}}>DEBIT AMOUNT</p> </Modal.Title>
//         </Modal.Header>
//         <Modal.Body style={{display:'flex', justifyContent:'center'}}>

//         <form className="form" >
     
//       <div>

//         <p style={{fontSize:'12px', color:'red', fontFamily:'monospace', fontWeight:'bold'}}>Available credit balance: {totalCredit?.user_currect_balance}</p>


//       <label>
//       <input
//         required
//         placeholder=""
//         type="text"
//         className="input"
//         name="description"
//         onChange={(e) => setInputDebitData((inputDebitData) => ({ ...inputDebitData,purpose_of_debit: e.target.value }))}
//       />
//       <span>Description</span>
//     </label>
  
//     <label>
//       <input
//         required
//         placeholder=""
//         type="number"
//         className="input"
//         name="amount"
//         onChange={(e) => setInputDebitData((inputDebitData) => ({ ...inputDebitData,debit_amount: e.target.value }))}
//       />
//       <span>Amount</span>
//     </label>


//       </div>


//     </form>
 

//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose1}>
//             Close
//           </Button>
//           <Button variant="danger" onClick={() => { handleDebit(); }}>
//   Debit
// </Button>

//         </Modal.Footer>
//       </Modal>
//         </div>):(<div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
//     <div>
//     <div className="loader">
//       <div className="loader__circle"></div>
//       <div className="loader__circle"></div>
//       <div className="loader__circle"></div>
//       <div className="loader__circle"></div>
//     </div>
//     </div>
//         </div>)
//       }



      
//     </div>
//   )
// }

// export default BalanceSheet