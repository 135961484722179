import React,{useContext} from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import MyContext from '../../store/MyContext'
import { BsEyeFill } from "react-icons/bs";
function UserViewBalanceSheet({userrfid,setViewBalance}) {

const {userid,setUserid}=useContext(MyContext)
    const handlViewBanalceSheet=()=>{
      setViewBalance(true)
      setUserid(userrfid)
    }
    
  return (
    <div>
        <Button className='btnn btn-light btn-sm' style={{padding:'-3px'}} onClick={handlViewBanalceSheet}>view <BsEyeFill/></Button>
    </div>
  )
}

export default UserViewBalanceSheet